import React, {useEffect, useRef, useState} from 'react';
import './color-dropdown.css';
import arrow from '../../assets/arr_down_white.jpeg';

export default function ColorDropdown({options = [], selectedColor, onChange}) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (color) => {
        onChange(color);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="color-dropdown" ref={dropdownRef}>
            <div className='wrapper' onClick={handleToggle}>
                <div className="color-option">
                    <div style={{background: selectedColor}} className='circle'>
                        &nbsp;
                    </div>
                </div>
                <img src={arrow} alt="arrow" className={`arrow ${isOpen ? 'open' : ''}`}/>
            </div>
            {isOpen && (
                <div className="menu">
                    {options.map((color) => (
                        <div
                            key={color}
                            className={`color-option ${selectedColor === color ? 'selected' : ''}`}
                            onClick={() => handleSelect(color)}>
                            <div style={{background: color}} className='circle'>
                                &nbsp;
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
