export const prettyDate = date => {
    if (!date) return ''
    const d = new Date(date)
    return `${d.getDate()} ${d.toLocaleString('en-US', {month: 'short'})} ${d.getFullYear()}`
}

export const toDanishDate = date => {
    if (!date) return ''
    const d = new Date(date)
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}

export const toDanishTime = date => {
    if (!date) return ''
    const d = new Date(date)
    return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
}

export const prettyTimeDifference = (d1, d2) => {
    const diff = (d1 - d2)/1000 // seconds

    if (diff < 5) {
        return `${Math.floor(diff)}s siden`
    }
    if (diff < 10) {
        return 'For nyligt'
    }
    if (diff < 60) {
        return `${Math.floor(diff)}s siden`
    }
    if (diff < 3600) {
        const minutes = Math.floor(diff/60)
        return `${minutes} minut${minutes === 1 ? '' : 'ter'} siden`
    }
    if (diff < 86400) {
        const hours = Math.floor(diff/3600)
        return `Ca ${hours} time${hours === 1 ? '' : 'r'} siden`
    }
    const days = Math.floor(diff/86400)
    return `${days} dag${days === 1 ? '' : 'e'} siden`
}
