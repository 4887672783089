import { useRef, useCallback } from 'react';
import html2pdf from 'html2pdf.js';
import './export-to-pdf.css';
import jsPDF from 'jspdf';
import leagueSpartanFontUrl from '../../fonts/LeagueSpartan-VariableFont_wght.ttf';
import ralewayFontUrl from '../../fonts/Raleway-VariableFont_wght.ttf';

// Helper to fetch a file and return a Base64 string (without data URL prefix)
async function getBase64FromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            // Remove the data URL prefix (e.g. "data:font/ttf;base64,")
            const base64 = reader.result.split(',')[1];
            resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function addFontToPDF(pdf, fontUrl, fontNameWithEnding, fontName) {
    const base64Font = await getBase64FromUrl(fontUrl);
    // Embed the font into jsPDF
    pdf.addFileToVFS(fontNameWithEnding, base64Font);
    pdf.addFont(fontNameWithEnding, 'LeagueSpartan', 'normal');
    pdf.setFont(fontName, 'normal');
}

export default function useExportToPDF(filename = 'proposal.pdf') {
    const exportRef = useRef(null);

    const exportToPDFold = useCallback(() => {
        if (!exportRef.current) return;
        const element = exportRef.current;
        element.classList.add('export-to-pdf-temporary');

        const options = {
            margin: 0.3,
            filename,
            image: { type: 'jpeg', quality: 0.99 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
        };

        html2pdf()
            .set(options)
            .from(element)
            .toContainer()
            .save()
            .finally(() => element.classList.remove('export-to-pdf-temporary'));
    }, [filename]);

    const exportToPDF = useCallback(async () => {
        if (!exportRef.current) return;
        const element = exportRef.current;
        // Add temporary styling
        element.classList.add('export-to-pdf-temporary');

        const margin = 72; // 1 inch in points
        const pdf = new jsPDF('p', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const availableWidth = pdfWidth - 2 * margin;
        const scale = availableWidth / element.offsetWidth;

        try {
            // await addFontToPDF(pdf, leagueSpartanFontUrl, 'LeagueSpartan.ttf', 'LeagueSpartan');
            // await addFontToPDF(pdf, ralewayFontUrl, 'Raleway.ttf', 'Raleway');
        } catch (error) {
            console.error('Error loading font:', error);
        }

        pdf.setCharSpace(0)

        pdf.html(element, {
            callback: (pdfInstance) => {
                pdfInstance.save(filename);
                // Remove temporary styling after export
                element.classList.remove('export-to-pdf-temporary');
            },
            x: margin,
            y: margin,
            html2canvas: { scale },
        });
    }, [filename]);

    return { exportRef, exportToPDF };
};
