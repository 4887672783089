import React, {useCallback, useEffect, useMemo, useState} from "react";
import InputField from "../input-field/InputField";
import bin from "../../assets/bin_v2.svg";
import {ItineraryApi} from "../../api";
import SingleCharButton from "../single-char-button/SingleCharButton";
import SimpleButton from "../simple-button/SimpleButton";
import './itinerary-timeplan.css';

export default function ItineraryTimeplan({ event_id, no_edit = false }) {
    const [program, setProgram] = useState(null);
    const [focusNewItem, setFocusNewItem] = useState(false);

    const addProgramItem = useCallback(() => {
        ItineraryApi.create_item(event_id, '', '', '').then(res => {
            setProgram(prev => ([...prev, res]));
            setFocusNewItem(res.id);
        })
    }, [program, setProgram]);

    const programIsDirty = useMemo(() => {
        if (!program) return false;
        return program.some(item => item.dirty);
    }, [program]);

    const saveProgram = useCallback(() => {
        if (!program) return;
        Promise.all(program.filter(i => i.dirty).map(item => {
            return ItineraryApi.update_item(item.id, item.from, item.to, item.text);
        })).then(() => {
            setProgram(prev => prev
                .map(p => ({...p, dirty: false}))
                .sort((a, b) => a.from.localeCompare(b.from)));
        }).catch(err => {
            console.error("Error saving program", err);
            // Optionally, handle error display to the user
        });
    }, [program]);

    useEffect(() => {

    }, [program]);

    useEffect(() => {
        if (!focusNewItem) return;
        console.log('Focus new item with id', focusNewItem);
        const el = document.querySelector('#program-id-' + focusNewItem + ' input');
        if (el) {
            console.log('Found element', el);
            el.focus();

            setFocusNewItem(false);
        } else {
            console.log('Element not found');
        }
    }, [focusNewItem]);

    useEffect(() => {
        ItineraryApi.list_items_by_event_id(event_id).then(res => {
            setProgram(res.sort((a, b) => {
                if (!a.from || !b.from) return 0;
                let [hourA, minuteA] = a.from.split(':').map(i => parseInt(i));
                let [hourB, minuteB] = b.from.split(':').map(i => parseInt(i));

                if (hourA < 7) hourA += 24;
                if (hourB < 7) hourB += 24;

                return hourA * 60 + minuteA - hourB * 60 - minuteB;
            }));
        });
    }, [event_id]);

    return (<section className='program-wrapper'>
        <h4>Tidsplan</h4>
        <div className='horizontal'>
            <p className='timeplan-intro'>
                Vælg tidspunkt og beskriv tidsplanen til programmet.
            </p>
            <div className='item-list'>
                {program !== null && program.map((item, index) => <div key={item.id}
                                                                       id={`program-id-${item.id}`}
                                                                       className='program-item'>
                    <div className='item-time-selection-wrapper'>
                        <span>fra</span>
                        <InputField type="time" value={item.from ?? ''} onChanged={value => setProgram(prev => {
                            return prev.map(p => p.id === item.id ? {
                                ...p, from: value, dirty: true
                            } : p)
                        })}/>
                        <span>til</span>
                        <InputField type="time" value={item.to ?? ''} onChanged={value => setProgram(prev => {
                            return prev.map(p => p.id === item.id ? {...p, to: value, dirty: true} : p)
                        })}/>
                    </div>
                    {!no_edit && <input
                        className='program-item-text'
                        type='text'
                        value={item.text}
                        disabled={no_edit}
                        onKeyUp={e => {
                            if (e.key === 'Enter') {
                                console.log('submit', e);
                                addProgramItem();
                            }
                        }}
                        onChange={e => {
                            const value = e.target.value;
                            setProgram(prev => {
                                const copy = [...prev];
                                copy[index].text = value;
                                copy[index].dirty = true;
                                return copy;
                            })
                        }}/>}
                    {no_edit && <div className='program-item-text'>
                        <p>{item.text}</p>
                    </div>}
                    {!no_edit && <SimpleButton icon={bin} just_text onClick={_ => {
                        ItineraryApi.delete_item(event_id, item.id).then(() => {
                            setProgram(prev => prev.filter(p => p.id !== item.id));
                        });
                    }}/>}
                </div>)}
                {!no_edit && <div className='program-item'>
                    <div className='grower'>
                        &nbsp;
                    </div>
                    <SingleCharButton char='+' onClick={_ => addProgramItem()}/>
                </div>}
            </div>
        </div>
        <div className='horizontal'>
            {!no_edit && <SimpleButton value='save' onClick={saveProgram} disabled={!programIsDirty}/>}
        </div>
    </section>)
}
