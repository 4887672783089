import axios from 'axios';


const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http:///127.0.0.1:5000',
    timeout: 10000
})

console.log('URL', process.env.REACT_APP_API_URL || 'http:///127.0.0.1:5000')

http.interceptors.request.use((config) => {
    if (config.url === '/user/authenticate') return config;

    const token = localStorage.getItem('token')
    if (token) {
        const expires = localStorage.getItem('tokenExpires')
        if (expires) {
            if (new Date(expires) < new Date()) {
                localStorage.removeItem('token')
                localStorage.removeItem('tokenExpires')
                throw new axios.Cancel('Token expired. Moving to login.');
            }
        }
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

http.interceptors.response.use(null, err => {
    if (err && err.response && err.response.status === 401) {
        window.location = '/logout'
    }
    throw err
});


const easyUnpack = ({data}) => data

const formatError = (response) => {
    const error = new Error(
        response.message ||
        'An unknown error occured'
    )
    error.payload = response.message

    throw error
}

export const UserApi = {
    create({name, email, phone, role, password}) {
        return http.post(`/user`, {
            name,
            email,
            phone,
            role,
            password,
        }).then(easyUnpack)
    },
    create_event_user(values) {
        return http.post(`/user/event-user`, values).then(easyUnpack)
    },
    update(user_id, new_values) {
        return http
            .patch(`/user/${user_id}`, new_values).then(easyUnpack)
    },
    authenticate(email, password, remember_me) {
        return http
            .post(`/user/authenticate`, {
                email,
                password,
                remember_me,
            })
            .then(easyUnpack)
            .catch(formatError)
    },
    get_user() {
        return http.get('/user').then(easyUnpack)
    },
    list_all_users() {
        return http.get('/user/list').then(easyUnpack)
    },
    get_user_events() {
        return http.get('/user/event').then(easyUnpack)
    }
}

export const SharedMessageApi = {
    list_message(message_identifier, event_id, venue_id) {
        return http.get(`/shared-message/search/${message_identifier}`, {
            params: {
                event_id,
                venue_id,
            }
        }).then(easyUnpack)
    },
    create_or_update(message_identifier, event_id, venue_id, message, proposal_order = null) {
        return http.post(`/shared-message/${message_identifier}`, {
            event_id,
            venue_id,
            message,
            proposal_order
        }).then(easyUnpack)
    },
    list_event_sections(event_id) {
        return http.get(`/shared-message/event/${event_id}/sections`).then(easyUnpack)
    },
    list_event_client_sections(event_id) {
        return http.get(`/shared-message/event/${event_id}/client-sections`).then(easyUnpack)
    },
    delete_event_message(event_id, message_id) {
        return http
            .delete(`/shared-message/event/${event_id}/msg/${message_id}`)
            .then(easyUnpack)
    },
    delete_venue_message(venue_id, message_id) {
        return http
            .delete(`/shared-message/venue/${venue_id}/msg/${message_id}`)
            .then(easyUnpack)
    },
    set_status_color(message_identifier, event_id, venue_id, status_color) {
        return http.post(`/shared-message/${message_identifier}/status`, {
            event_id,
            venue_id,
            status_color
        }).then(easyUnpack)
    },
    set_client_visibility(message_identifier, event_id, venue_id, client_visibility) {
        return http.post(`/shared-message/${message_identifier}/client-visibility`, {
            event_id,
            venue_id,
            client_visibility
        }).then(easyUnpack)
    },
    list_proposal_paragraphs(venue_id, event_id) {
        return http.get(`/shared-message/proposal-paragraphs`, {
            params: {
                venue_id,
                event_id
            }
        }).then(easyUnpack)
    },
    batchUpdateProposalOrder(venue_id, event_id, updated_orders) {
        return http.post(`/shared-message/proposal-orders`, {
            venue_id,
            event_id,
            updated_orders
        }).then(easyUnpack)
    }
}

export const EventApi = {
    create(values) {
        return http.post(`/event`, values).then(easyUnpack)
    },
    add_room_to_event(event_id, room_id) {
        return http.post(`/event/${event_id}/room/${room_id}`).then(easyUnpack)
    },
    update(event_id, new_values) {
        return http
            .patch(`/event/${event_id}`, new_values).then(easyUnpack)
    },
    delete(event_id) {
        return http
            .delete(`/event/${event_id}`)
            .then(easyUnpack)
    },
    get_event_room(event_id) {
        return http.get(`/event/${event_id}/room`).then(easyUnpack)
    },
    list_rooms_by_event_id(event_id) {
        return http.get(`/event/${event_id}/rooms`).then(easyUnpack)
    },
    get_by_id(event_id) {
        return http.get(`/event/${event_id}`).then(easyUnpack)
    },
    list_seating_overview(event_id) {
        return http.get(`/event/${event_id}/seating-overview`).then(easyUnpack)
    },
    list_dietary_overview(event_id) {
        return http.get(`/event/${event_id}/dietary-overview`).then(easyUnpack)
    },
    list_users_by_event_id(event_id) {
        return http.get(`/event/${event_id}/users`).then(easyUnpack)
    },
    delete_all_users_by_event_id(event_id) {
        return http.delete(`/event/${event_id}/guests`).then(easyUnpack)
    }
};

export const ItineraryApi = {
    create_item(event_id, from_time, to_time, text) {
        return http.post(`/itinerary/event/${event_id}`, {
            from_time,
            to_time,
            text
        }).then(easyUnpack)
    },
    update_item(itinerary_id, from_time, to_time, text) {
        return http.patch(`/itinerary/item/${itinerary_id}`, {
            from_time,
            to_time,
            text
        }).then(easyUnpack)
    },
    list_items_by_event_id(event_id) {
        return http.get(`/itinerary/event/${event_id}`).then(easyUnpack)
    },
    delete_item(event_id, itinerary_id) {
        return http.delete(`/itinerary/event/${event_id}/item/${itinerary_id}`).then(easyUnpack)
    },
}

export const TableApi = {
    list_tables_for_room(room_id) {
        return http
            .get(`/room/${room_id}/table`, {
                params: {
                    with_seating: true
                }
            })
            .then(easyUnpack)
    },
    list_table_templates(room_id, venue_id) {
        return http
            .get(`/table/table-templates`, {
                params: {
                    room_id,
                    venue_id
                }
            })
            .then(easyUnpack)
    },
    seat_guests_with_count(table_id, guest_id, count) {
        return http
            .post(`/table/${table_id}/new-seat`, {
                guest_id,
                count
            })
            .then(easyUnpack)
    },
    seat_guests(table_id, guest_ids) {
        return http
            .post(`/table/${table_id}/seating`, {
                guest_ids
            })
            .then(easyUnpack)
    },
    unseat_guests(guest_ids) {
        return http
            .post(`/table/unseat`, {
                guest_ids
            })
            .then(easyUnpack)
    },
    create(values) {
        const {
            position,
            max_seating,
            ...rest
        } = values
        return http.post(`/table`, {
            ...rest,
            max_seating,
            active_seats: max_seating,
            position: {x: Math.floor(position.x), y: Math.floor(position.y)},
        }).then(easyUnpack)
    },
    update_table(table_id, new_values) {
        return http
            .patch(`/table/${table_id}`, new_values)
            .then(easyUnpack)
    },
    batch_update_tables(tables) {
        return http
            .patch(`/table/multiple`, {tables})
            .then(easyUnpack)
    },
    delete_table(table_id) {
        return http
            .delete(`/table/${table_id}`)
            .then(easyUnpack)
    }
}

export const GuestAPI = {
    parseExcelGuestList(file_name, file_data) {
        return http.post(`/guest/parse`, {
            file_name,
            file_data,
        }).then(easyUnpack)
    },
    createGuests(event_id, guests, headers, header_map) {
        return http.post('/guest/parse/import', {
            event_id,
            guests,
            headers,
            header_map,
        }).then(easyUnpack)
    },
    batchUpdateGuests(guest_value_map) {
        return http.patch('/guest/batch', {
            guest_value_map
        }).then(easyUnpack)
    },
    listEventGuests(event_id, with_seating=false) {
        return http.get(`/event/${event_id}/guests`, {
            params: {
                with_seating
            }
        }).then(easyUnpack)
    },
    listEventGuestsById(guest_ids) {
        return http.get(`/event/guests`, {
            params: {
                guest_ids: guest_ids.join(',')
            }
        }).then(easyUnpack)
    },
    deleteGuestById(guest_id) {
        return http.delete(`/guest/${guest_id}`).then(easyUnpack)
    },
    createGuest(values) {
        return http.post('/guest', values).then(easyUnpack)
    }
}

export const VenueAPI = {
    list_all_venues() {
        return http.get('/venue').then(easyUnpack)
    },
    get_venue_by_id(venue_id) {
        return http.get(`/venue/${venue_id}`).then(easyUnpack)
    },
    create(name, address) {
        return http.post('/venue', {
            name,
            address
        }).then(easyUnpack)
    },
    list_venue_rooms(venue_id) {
        return http.get(`/venue/${venue_id}/room`).then(easyUnpack)
    },
    list_venue_events(venue_id) {
        return http.get(`/venue/${venue_id}/event`).then(easyUnpack)
    },
    list_all_venue_clients(venue_id) {
        return http.get(`/venue/${venue_id}/all-clients`).then(easyUnpack)
    },
    get_calendar_token(venue_id) {
        return http.get(`/venue/${venue_id}/calendar-token`).then(easyUnpack)
    }
}

export const RoomAPI = {
    create({venue_id, name, position_x, position_y, width_meter, height_meter, image_name, image_data}) {
        return http.post('/room', {
            venue_id,
            name,
            position_x,
            position_y,
            width_meter,
            height_meter,
            image_name,
            image_data,
        }).then(easyUnpack)
    },
    get_room_by_id(room_id) {
        return http.get(`/room/${room_id}`).then(easyUnpack)
    },
    update_room(room_id, values) {
        const {name, width_meter, height_meter, pixel_pr_meter} = values
        console.log('pixel_pr_meter', pixel_pr_meter)
        console.log('VALUES', values)
        return http.patch(`/room/${room_id}`, {
            name, width_meter, height_meter, pixel_pr_meter,
        }).then(easyUnpack)
    },
    delete_room(room_id) {
        return http.delete(`/room/${room_id}`).then(easyUnpack)
    }
}

export const adminAPI = {
    list_venue_users(venue_id) {
        return http.get(`/admin/venue/${venue_id}/users`).then(easyUnpack)
    },
    list_user_venues(user_id) {
        return http.get(`/admin/user/${user_id}/venues`).then(easyUnpack)
    },
    list_all_venues() {
        return http.get(`/admin/venues`).then(easyUnpack)
    },
    list_all_events() {
        return http.get(`/admin/events`).then(easyUnpack)
    },
    add_user_to_venue(user_id, venue_id) {
        return http.put(`/admin/user/${user_id}/venue/${venue_id}`).then(easyUnpack)
    },
    remove_user_from_venue(user_id, venue_id) {
        return http.delete(`/admin/user/${user_id}/venue/${venue_id}`).then(easyUnpack)
    },
    add_user_to_event(user_id, event_id) {
        return http.put(`/admin/user/${user_id}/event/${event_id}`).then(easyUnpack)
    },
    remove_user_from_event(user_id, event_id) {
        return http.delete(`/admin/user/${user_id}/event/${event_id}`).then(easyUnpack)
    },
    list_events_by_user_id(user_id) {
        return http.get(`/admin/user/${user_id}/events`).then(easyUnpack)
    },
    delete_user(user_id) {
        return http
            .delete(`/admin/user/${user_id}`)
            .then(easyUnpack)
    },
    patch_user(user_id, password = null) {
        return http.patch(`/admin/user/${user_id}`, {
            password,
        }).then(easyUnpack)
    },
    create_venue(name, address) {
        return http.post(`/admin/venue`, {
            name,
            address
        }).then(easyUnpack)
    }
}

export const exportAPI = {
    export_guest_list_by_event_id(event_id) {
        return http.get(`export/event/${event_id}/guest-list`).then(easyUnpack)
    },
    export_waiter_list_by_event_id(event_id) {
        return http.get(`export/event/${event_id}/waiter-list`).then(easyUnpack)
    },
};

export const invitationAPI = {
    get_invitation_by_token(token) {
        return http.get(`/invitation/${token}`).then(easyUnpack)
    },
    submit_answers(token, answers) {
        return http.post(`/invitation/${token}`, {answers}).then(easyUnpack)
    },
    mark_as_seen(token) {
        return http.post(`/invitation/${token}/seen`).then(easyUnpack)
    },
    list_invitations(event_id) {
        return http.get(`/invitation/event/${event_id}/overview`).then(easyUnpack)
    },
}


export const invitationAdminAPI = {
    create_question(event_id, question_text, question_type, choices) {
        return http.post(`/invitation/admin/${event_id}/question`, {
            question_text,
            question_type,
            choices,
        }).then(easyUnpack)
    },
    delete_question(event_id, question_id) {
        return http.delete(`/invitation/admin/${event_id}/question/${question_id}`).then(easyUnpack)
    },
    list_questions(event_id) {
        return http.get(`/invitation/admin/${event_id}/question`).then(easyUnpack)
    },
    update_question(event_id, question_id, values) {
        return http.patch(`/invitation/admin/${event_id}/question/${question_id}`, {
            values
        }).then(easyUnpack)
    },
    list_invitations(event_id) {
        return http.get(`/invitation/event/${event_id}`).then(easyUnpack)
    },
    create_invitation(event_id, email, guest_name) {
        return http.post(`/invitation/event/${event_id}`, {
            email,
            guest_name
        }).then(easyUnpack)
    }
};

export const stationAPI = {
    create_or_update_assignment(event_id, station_identifier, assigned) {
        return http.post(`/station/event/${event_id}`, {
            station_identifier,
            assigned
        }).then(easyUnpack)
    },
    create_or_update_assignment_batch(event_id, updated) {
        return http.post(`/station/event/${event_id}/batch`, {
            updated,
        }).then(easyUnpack)
    },
    list_by_event_id(event_id) {
        return http.get(`/station/event/${event_id}`).then(easyUnpack)
    }
}

export const roomElementAPI = {
    create_room_element_template(type, properties) {
        return http.post(`/element`, {
            type,
            is_template: true,
            properties
        }).then(easyUnpack)
    },
    create_room_element(room_id, type, properties) {
        return http.post(`/element`, {
            room_id,
            type,
            properties,
            is_template: false,
        }).then(easyUnpack)
    },
    delete_room_element(element_id) {
        return http.delete(`/element/${element_id}`).then(easyUnpack)
    },
    update_room_element(element_id, properties) {
        return http.patch(`/element/${element_id}`, {
            properties
        }).then(easyUnpack)
    },
    list_templates() {
        return http.get(`/element/template`).then(easyUnpack)
    },
    list_room_elements(room_id) {
        return http.get(`/element/room/${room_id}`).then(easyUnpack)
    }
};

export const marketingApi = {
    contact_me(values) {
        return http.post(`/marketing/contact-me`, values).then(easyUnpack)
    },
    email_subscription(values) {
        return http.post(`/marketing/subscribe`, values).then(easyUnpack)
    }
}

export const packageApi = {
    listPackagesForVenue(venue_id) {
        return http.get(`/packages/venue/${venue_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    createPackage(title, venue_id, event_id, proposal_id, type, price_type, description = null, proposal_order = 0) {
        return http.post(`/packages`, {
            title,
            venue_id,
            event_id,
            proposal_id,
            type,
            price_type,
            description,
            proposal_order
        })
            .then(easyUnpack)
            .catch(formatError)
    },
    getPackage(package_id) {
        return http.get(`/packages/${package_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    updatePackage(package_id, {title = null, description = null, price_type = null} = {}) {
        return http.put(`/packages/${package_id}`, {title, description, price_type})
            .then(easyUnpack)
            .catch(formatError)
    },
    archivePackage(package_id) {
        return http.delete(`/packages/${package_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    createOptionForPackage(package_id, name, description, netto_amount, total_amount) {
        return http.post(`/packages/${package_id}/options`, {name, description, netto_amount, total_amount})
            .then(easyUnpack)
            .catch(formatError)
    },
    updateOption(package_id, option_id, {
        name = null,
        description = null,
        netto_amount = null,
        total_amount = null
    }) {
        return http.put(`/packages/${package_id}/options/${option_id}`, {name, description, netto_amount, total_amount})
            .then(easyUnpack)
            .catch(formatError)
    },
    deleteOption(package_id, option_id) {
        return http.delete(`/packages/${package_id}/options/${option_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    deletePackage(package_id) {
        return http.delete(`/packages/${package_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    listPackagesForEvent(event_id) {
        return http.get(`/packages/event/${event_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    listPackagesForProposal(proposal_id) {
        return http.get(`/packages/proposal/${proposal_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    addPackageToEvent(package_id, event_id) {
        return http.post(`/packages/${package_id}/event/${event_id}`)
            .then(easyUnpack)
            .catch(formatError)
    },
    batchUpdatePackageOrders(venue_id, event_id, updated_orders) {
        return http.post(`/packages/package-orders`, {
            venue_id,
            event_id,
            updated_orders
        })
            .then(easyUnpack)
            .catch(formatError)
    },
    selectOption(package_id, option_id, selected) {
        return http.post(`/packages/${package_id}/options/${option_id}/select`, {selected})
            .then(easyUnpack)
            .catch(formatError)
    }
}

export const customerApi = {
    listCustomersForVenue(venue_id) {
        return http.get(`/customer/venue/${venue_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    createCustomer({
                       venue_id,
                       company_name = null,
                       company_cvr = null,
                       company_address = null,
                       contact_name = null,
                       contact_phone = null,
                       contact_email = null,
                       company_country = null,
                   }) {
        return http.post(`/customer`, {
            venue_id,
            company_name,
            company_cvr,
            company_address,
            contact_name,
            contact_phone,
            contact_email,
            company_country,
        }).then(easyUnpack)
            .catch(formatError);
    },
    getCustomer(customer_id) {
        return http.get(`/customer/${customer_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    updateCustomer(customer_id, {
        company_name = null,
        company_cvr = null,
        company_address = null,
        contact_name = null,
        contact_phone = null,
        contact_email = null,
        role = null,
        login_username = null,
        password = null
    }) {
        return http.patch(`/customer/${customer_id}`, {
            company_name,
            company_cvr,
            company_address,
            contact_name,
            contact_phone,
            contact_email,
            role,
            login_username,
            password
        }).then(easyUnpack)
            .catch(formatError);
    },
    deleteCustomer(customer_id) {
        return http.delete(`/customer/${customer_id}`)
            .then(easyUnpack)
            .catch(formatError);
    }
};

export const economyApi = {
    get_event_specific_cost() {
        return http.get('/economy/event-specific-cost').then(easyUnpack)
    }
}

export const proposalApi = {
    listProposalsForVenue(venue_id) {
        return http.get(`/proposal/venue/${venue_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    listProposalsForEvent(event_id) {
        return http.get(`/proposal/event/${event_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    getLatestProposalForEvent(event_id) {
        return http.get(`/proposal/event/${event_id}/latest`)
            .then(easyUnpack)
            .catch(formatError);
    },
    getLatestProposalForVenue(venue_id) {
        return http.get(`/proposal/venue/${venue_id}/latest`)
            .then(easyUnpack)
            .catch(formatError);
    },

    uploadDocumentLogo(proposal_id, image_data) {
        return http.post(`/proposal/${proposal_id}/document-logo`, {
            image_data
        }).then(easyUnpack)
    },
    getDocumentLogo(proposal_id) {
        return http.get(`/proposal/${proposal_id}/document-logo`).then(easyUnpack)
    },

    createProposal({
                       event_id = null,
                       venue_id = null,
                       name,
                       status = null,
                       proposal_order = null,
                       proposal_deadline = null,
                       sent_to_customer_id = null,
                       locked = false,
                       pdf_bucket_path = null
                   }) {
        return http.post(`/proposal`, {
            event_id,
            venue_id,
            name,
            status,
            proposal_order,
            proposal_deadline,
            sent_to_customer_id,
            locked,
            pdf_bucket_path
        })
            .then(easyUnpack)
            .catch(formatError);
    },

    generateTokenAndLock(proposal_id, proposal_deadline) {
        return http.post(`/proposal/${proposal_id}/lock-to-send`, {
            proposal_deadline
        })
            .then(easyUnpack)
            .catch(formatError);
    },

    getProposalToken(proposal_id) {
        return http.get(`/proposal/${proposal_id}/token`)
            .then(easyUnpack)
            .catch(formatError);
    },

    viewProposalUsingtoken(token) {
        return http.get(`/proposal/token/${token}`)
            .then(easyUnpack)
            .catch(formatError);
    },

    updateProposal(proposal_id, {
        name = null,
        status = null,
        proposal_order = null,
        proposal_deadline = null,
        sent_to_customer_id = null,
        locked = null,
        pdf_bucket_path = null
    }) {
        return http.put(`/proposal/${proposal_id}`, {
            name,
            status,
            proposal_order,
            proposal_deadline,
            sent_to_customer_id,
            locked,
            pdf_bucket_path
        })
            .then(easyUnpack)
            .catch(formatError);
    },

    markProposalSeen(proposal_id) {
        return http.post(`/proposal/${proposal_id}/mark-seen`)
            .then(easyUnpack)
            .catch(formatError);
    },

    deleteProposal(proposal_id) {
        return http.delete(`/proposal/${proposal_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    getProposalById(proposal_id) {
        return http.get(`/proposal/${proposal_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },

    createDraftFromProposal(proposal_id) {
        return http.post(`/proposal/${proposal_id}/create-draft`)
            .then(easyUnpack)
            .catch(formatError);
    },

    uploadProposalPdf(proposal_id, pdf_data) {
        return http.post(`/proposal/${proposal_id}/pdf`, {
            pdf_uridatastring: pdf_data
        })
            .then(easyUnpack)
            .catch(formatError);
    },
    acceptProposal(proposal_id, token) {
        return http.post(`/proposal/${proposal_id}/accept`, {
            token
        })
            .then(easyUnpack)
            .catch(formatError);

    },
    getProposalPdfSignedUrl(proposal_id) {
        return http.get(`/proposal/${proposal_id}/pdf/signed-url`)
            .then(easyUnpack)
            .catch(formatError);
    },
    addProposalToEvent(proposal_id, event_id) {
        return http.post(`/proposal/${proposal_id}/event/${event_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    deleteDocumentLogo(proposal_id) {
        return http.delete(`/proposal/${proposal_id}/document-logo`)
            .then(easyUnpack)
            .catch(formatError);
    }
};

export const hostApi = {
    getVenueHost(venue_id) {
        return http.get(`/host/venue/${venue_id}`)
            .then(easyUnpack)
            .catch(formatError);
    },
    createOrUpdate(venue_id, username, password) {
        return http.post(`/host/venue/${venue_id}`, {username, password})
            .then(easyUnpack)
            .catch(formatError);
    },
    checkinGuest(event_id, guest_id, host_name, door_name, comment, checked_in) {
        return http.post(`/host/event/${event_id}/checkin/${guest_id}`, {
            event_id,
            host_name,
            door_name,
            comment,
            checked_in
        })
            .then(easyUnpack)
            .catch(formatError);
    },
    listEventCheckins(event_id) {
        return http.get(`/host/event/${event_id}/checkins`)
            .then(easyUnpack)
            .catch(formatError);
    },
    deleteCheckin(checkin_id) {
        return http
            .delete(`/host/checkin/${checkin_id}`)
            .then(easyUnpack)
    }
}
