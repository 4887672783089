import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import './create-new-event.css'
import {selectVenueById, selectVenueLoading} from "../../slice/venueSlice";
import {
    doCreateEvent,
    doFetchVenueEvents,
    selectCreatingEvent,
    selectVenueEvents,
    updateLocalEvent
} from "../../slice/eventSlice";
import {doFetchTableTemplates} from "../../slice/tableSlice";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import checkmark from '../../assets/checkmark.svg';
import {doFetchVenueRooms, selectVenueRooms} from "../../slice/roomSlice";
import SimpleInput from "../simple-input/SimpleInput";
import {customerApi, packageApi, proposalApi} from "../../api";
import NewCustomerPopup from "../customer/new-customer/NewCustomer";
import CenteredPopup from "../centered-popup/CenteredPopup";
import {toDanishDate} from "../date_functions";

export default function CreateNewEvent({callback, default_date = null, showPopup, closePopup}) {
    const dispatch = useDispatch()

    const {venue_id: _vanue_id} = useParams()
    const venue_id = useMemo(() => parseInt(_vanue_id), [_vanue_id])

    const venue = useSelector(selectVenueById(venue_id));
    const events = useSelector(selectVenueEvents(venue_id));
    const rooms = useSelector(selectVenueRooms(venue_id));
    const creatingEvent = useSelector(selectCreatingEvent);
    const venueLoading = useSelector(selectVenueLoading);

    const [customers, setCustomers] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [creatingCustomer, setCreatingCustomer] = useState(false);

    const [proposals, setProposals] = useState(null);

    useEffect(() => {
        if (!venue_id) return;
        dispatch(doFetchVenueRooms(venue_id));
        dispatch(doFetchTableTemplates({venue_id, room_id: null}));
        dispatch(doFetchVenueEvents(venue_id))

        proposalApi.listProposalsForVenue(venue_id).then(res => {
            setProposals(res || []);
        });

        customerApi.listCustomersForVenue(venue_id).then(res => {
            setCustomers(res)
        })
    }, [dispatch, venue_id])

    useEffect(() => {
        if (!default_date) return;
        setEventDate(toDanishDate(default_date));
    }, [default_date]);

    // new event creation
    const [eventTitle, setEventTitle] = useState('')
    const [saleStatus, setSaleStatus] = useState('won')

    const [eventDate, setEventDate] = useState(default_date ? default_date : '')
    const [timestampFrom, setTimestampFrom] = useState('')
    const [timestampTo, setTimestampTo] = useState('')
    const [expectedGuests, setExpectedGuests] = useState('')
    const [eventRooms, setEventRooms] = useState([]);
    const [localEventLoading, setLocalEventLoading] = useState(false);

    const [selectedDocuments, setSelectedDocuments] = useState([]);

    useEffect(() => {
        if (localEventLoading && !creatingEvent) {
            setLocalEventLoading(false);

            setEventTitle('')
            setEventDate('')
            setTimestampFrom('')
            setTimestampTo('')
            setExpectedGuests('')
            setEventRooms([])
            if (callback !== null) callback(false)
        }
    }, [localEventLoading, creatingEvent, callback])

    const createEvent = useCallback(() => {
        setLocalEventLoading(true)

        let sale_status = 'won';
        if (saleStatus === 'Tilbud') sale_status = 'proposal';
        if (saleStatus === 'Tabt') sale_status = 'lost';

        dispatch(doCreateEvent({
            venue_id,
            proposal_ids: selectedDocuments,
            title: eventTitle,
            date: eventDate,
            timestamp_from: timestampFrom.length > 0 ? timestampFrom : null,
            timestamp_to: timestampTo.length > 0 ? timestampTo : null,
            expected_guests: expectedGuests,
            room_ids: eventRooms,
            customer_id: selectedCustomer ? selectedCustomer.id : null,
            sale_status
        })).then(_ => {
            setLocalEventLoading(false)
        })
    }, [dispatch, venue_id, eventTitle, eventDate, timestampFrom,
        timestampTo, expectedGuests, eventRooms, selectedCustomer, saleStatus, selectedDocuments]);

    const eventCreatable = useMemo(() => {
        return eventTitle.length > 0 && eventDate.length > 0 && expectedGuests.length > 0 && eventRooms.length > 0
    }, [eventTitle, eventDate, timestampFrom, timestampTo, expectedGuests, eventRooms, selectedCustomer]);

    if (!venue) return <p>Loading..</p>

    return (<>
            <CenteredPopup showPopup={showPopup} closePopup={closePopup} className={'create-new-event'}>
                <h1>Nyt Event</h1>
                <SimpleInput value={eventTitle} required title={'Event title'} onChanged={setEventTitle}/>

                <h4>Køber</h4>
                <SimpleInput
                    options={customers ? customers.map(c => c.company_name) : []}
                    value={selectedCustomer?.company_name}
                    onlySelectableOptions
                    readOnly
                    canCreateNew
                    onChanged={name => {
                        setSelectedCustomer(customers.find(c => c.company_name === name))
                    }}
                    onClickedCreateNew={_ => setCreatingCustomer(true)}
                />

                <div className='horizontal'>
                    <SimpleInput title='Salgs status'
                                 options={['Tilbud', 'Vundet', 'Tabt']}
                                 onlySelectableOptions
                                 value={{
                                     'proposal': 'Tilbud',
                                     'won': 'Vundet',
                                     'lost': 'Tabt'
                                 }[saleStatus]}
                                 onChanged={sale_status => {
                                     const translated = {
                                         'Tilbud': 'proposal',
                                         'Vundet': 'won',
                                         'Tabt': 'lost'
                                     }[sale_status]
                                     setSaleStatus(translated)
                                 }}/>
                    <SimpleInput value={expectedGuests}
                                 type='number'
                                 required
                                 title='Forventet antal gæster'
                                 onChanged={setExpectedGuests}/>
                </div>


                <div className='date-time-selection-wrapper'>
                    <InputField value={eventDate} required type='date' title='Dato' onChanged={setEventDate}/>
                    <span>Fra</span>
                    <SimpleInput type="time" value={timestampFrom} onChanged={setTimestampFrom}/>
                    <span>Til</span>
                    <SimpleInput type="time" value={timestampTo} onChanged={setTimestampTo}/>
                </div>

                <div className='horizontal'>
                    <div>
                        <h3>Lokaler</h3>
                        <div className='room-selection'>
                            {rooms.map(room => <div
                                className={`room-it ${eventRooms.indexOf(room.id) !== -1 && 'selected'}`}
                                key={room.id}
                                onClick={_ => setEventRooms(prev => (prev.indexOf(room.id) !== -1) ? prev.filter(e => e !== room.id) : [...prev, room.id])}>
                                <span>{room.name}</span>
                                <img src={checkmark} alt='checkmark'/>
                            </div>)}
                        </div>
                    </div>
                    <div>
                        <h3>Dokumenter</h3>
                        <div className='room-selection'>
                            {proposals && proposals.map(room => <div
                                className={`room-it ${selectedDocuments.indexOf(room.id) !== -1 && 'selected'}`}
                                key={room.id}
                                onClick={_ => setSelectedDocuments(prev => (prev.indexOf(room.id) !== -1) ? prev.filter(e => e !== room.id) : [...prev, room.id])}>
                                <span>{room.name}</span>
                                <img src={checkmark} alt='checkmark'/>
                            </div>)}
                        </div>
                    </div>
                </div>

                <div className='grower'>&nbsp;</div>

                <div className='submit-btn-wrapper'>
                    <SimpleButton value='Annuller' onClick={_ => callback(false)}/>
                    <SimpleButton disabled={!eventCreatable}
                                  value='Opret event'
                                  onClick={createEvent}
                                  loading={creatingEvent}/>
                </div>
            </CenteredPopup>

            <NewCustomerPopup
                venue_id={venue_id}
                isOpen={creatingCustomer}
                createdNewCustomer={cust => {
                    setCustomers(prev => [...prev, cust])
                    setSelectedCustomer(cust)
                }}
                onClose={_ => setCreatingCustomer(false)}
                title='Opretter ny køber'/>
        </>
    )
}
