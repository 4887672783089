import React from "react";
import './client-event-option.css';
import priceTag from '../../../../assets/price_tag.svg';

export default function ClientEventoption({opt, pkg, idx, className}) {
    return (<div className='client-event-option' key={opt.id}>
        {/*<p>{opt.description}</p>*/}
        <div>
            <h3>{pkg.proposal_order + 1}.{idx + 1} {opt.name}</h3>
            {opt.description.split('\n').map((line, idx) => <p
                className='printer-friendly-paragraphs client-paragraph-description'
                key={idx}>{line}</p>)}
        </div>
        <label className='price-tag'>
            <img src={priceTag} alt='price tag'/>
            Pris: {opt.total_amount} DKK {pkg.price_type === 'px' ? 'pr person' : 'flad rate'}
        </label>
    </div>)
}
