import React, {useCallback, useEffect, useMemo, useState} from "react";
import {GuestAPI, hostApi} from "../../../api";
import {useParams} from "react-router-dom";
import './host-event-admin-page.css';
import checkmark from '../../../assets/checkmark_green.svg';
import arrow from '../../../assets/arr_down_black.svg';
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {prettyTimeDifference, toDanishTime} from "../../../component/date_functions";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import ValueKeyLabel from "../../../component/value-key-label/ValueKeyLabel";
import SimpleInput from "../../../component/simple-input/SimpleInput";

export default function HostEventAdminPage() {
    let {event_id} = useParams();

    const [checkins, setCheckins] = useState([]);
    const [eventGuests, setEventGuests] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");

    const [automaticUpdates, setAutomaticUpdates] = useState(true);

    const [lastUpdated, setLastUpdated] = useState(null);

    const [selectedGuest, setSelectedGuest] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [checkinComment, setCheckinComment] = useState('');

    useEffect(() => {
        if (!event_id) return;
        GuestAPI.listEventGuests(event_id, true).then(guests => {
            setEventGuests(guests);
        });
        hostApi.listEventCheckins(event_id).then(setCheckins);
    }, [event_id]);

    useEffect(() => {
        if (!automaticUpdates) return;
        hostApi.listEventCheckins(event_id, true).then(cks => {
            setCheckins(cks);
            setLastUpdated(new Date());
        });
        const interval = setInterval(() => {
            if (!event_id) return;
            hostApi.listEventCheckins(event_id, true).then(setCheckins);
            setLastUpdated(new Date());
        }, 5000);

        return () => clearInterval(interval);
    }, [automaticUpdates]);

    const [timeAgo, setTimeAgo] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            const ta = prettyTimeDifference(new Date(), lastUpdated)
            setTimeAgo(ta);
        }, 1000);
        return () => clearInterval(interval);
    }, [lastUpdated, automaticUpdates]);

    const guestsWithCheckins = useMemo(() => {
        if (!eventGuests) return [];
        return eventGuests.map(g => {
            return {
                ...g,
                checkins: checkins.filter(c => c.guest_id === g.id)
            }
        });
    }, [eventGuests, checkins]);

    const allDoorNames = useMemo(() => {
        return [...new Set(checkins.map(c => c.door_name))];
    }, [checkins]);

    const sortedGuests = useMemo(() => {
        if (!sortColumn) return guestsWithCheckins;
        const sorted = [...guestsWithCheckins];
        sorted.sort((a, b) => {
            let valueA = sortColumn === "name" ? a.name : a.checkins.filter(c => c.door_name === sortColumn).length;
            let valueB = sortColumn === "name" ? b.name : b.checkins.filter(c => c.door_name === sortColumn).length;

            if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
            if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });
        return sorted;
    }, [guestsWithCheckins, sortColumn, sortOrder]);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortColumn(column);
            setSortOrder("desc");
        }
    };

    const {hasCheckedIn} = useMemo(() => {
        if (!guestsWithCheckins) return {hasCheckedIn: 0, hasNotCheckedIn: 0};
        const hasCheckedIn = guestsWithCheckins.filter(g => g.checkins.length > 0);
        return {hasCheckedIn: hasCheckedIn.length};
    }, [guestsWithCheckins]);

    return (
        <div className='host-event-admin-page'>
            <div className='horizontal center header'>
                <h3>Eventvært Admin</h3>
                <div className='grower'/>
                <label>
                    <label>Check ind status: {hasCheckedIn}</label>
                    <b>/</b>
                    <label className='overall-checkin-count'>
                        {guestsWithCheckins?.length}
                    </label>
                </label>
                <div className='grower'/>
                <div>
                    <p>Opdateret</p>
                    <label>
                        {timeAgo}
                    </label>
                </div>
                <div className='grower'/>
                <SimpleButton
                    value={automaticUpdates ? 'Stop automatisk opdatering' : 'Start automatisk opdatering'}
                    onClick={_ => setAutomaticUpdates(!automaticUpdates)}
                />
            </div>
            <p>
                Her kan du se hvilke gæster der er tjekket ind ved de forskellige døre.
            </p>
            <table>
                <thead>
                <tr>
                    <th onClick={() => handleSort("name")}>
                        Gæst {sortColumn === "name" ? (sortOrder === "asc" ? <img src={arrow}/> :
                        <img src={arrow} className={'rotate'}/>) : ""}
                    </th>
                    {allDoorNames.map(doorName => (
                        <th key={doorName} onClick={() => handleSort(doorName)}>
                            {doorName} {sortColumn === doorName ? (sortOrder === "asc" ? <img src={arrow}/> :
                            <img src={arrow} className={'rotate'}/>) : ""}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {sortedGuests.map(g => (
                    <tr key={g.id} onClick={_ => setSelectedGuest(g)}>
                        <td>{g.name}</td>
                        {allDoorNames.map(doorName => {
                            const checkin = g.checkins.find(c => c.door_name === doorName);
                            return <td key={doorName}>
                                {checkin ? <img src={checkmark}/> : ''}
                            </td>;
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
            {selectedGuest && <CenteredPopup className='checkin-popup' showPopup={selectedGuest !== null}
                                             closePopup={_ => setSelectedGuest(null)}>
                <h2>Tjek ind <b>{selectedGuest.name}</b></h2>
                <div className='count-wrapper'>
                    <label className={'count-label'}>{selectedGuest.count} </label>
                    <label>personer i selskabet</label>
                </div>
                <div>
                    <ValueKeyLabel title='Firmanavn'>
                    </ValueKeyLabel>
                    {selectedGuest.company}

                    <br />
                    <br />

                    {selectedGuest.comment && <ValueKeyLabel title='Seating kommentar'>
                    </ValueKeyLabel>}
                    {selectedGuest.comment}
                </div>
                {selectedGuest.seating && <div>
                    <b>Seating</b>
                    {selectedGuest.seating.map(s => <div key={s.id} className='guest-seating'>
                        <label>Bord: {s.table_name} ({s.seated_count} pladser)</label>
                        {/*<div className='table-color' style={{background: s.table_color}}>&nbsp;</div>*/}
                    </div>)}
                </div>}
                <div className='prev-checkins'>
                    <b>Tidligere indtjekninger</b>
                    <hr/>
                    {selectedGuest.checkins.length === 0 && <p>Ingen tidligere indtjekninger</p>}
                    {selectedGuest.checkins.length > 0 && <table>
                        <thead>
                        <tr>
                            <th>Dør</th>
                            <th>Vært</th>
                            <th>Tidspunkt</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedGuest.checkins.map(c => <tr key={c.id}>
                            <td>{c.door_name}</td>
                            <td>{c.host_name}</td>
                            <td>{toDanishTime(c.timestamp_created)} ({prettyTimeDifference(new Date(), new Date(c.timestamp_created))})</td>
                            <td className='delete-td'>
                                <SimpleButton
                                    value='Slet'
                                    onClick={_ => {
                                        if (window.confirm('Er du sikker på du vil slette denne indtjekning?')) {
                                            hostApi.deleteCheckin(c.id).then(_ => {
                                                setCheckins(prev => prev.filter(ci => ci.id !== c.id));
                                                setSelectedGuest(prev => {
                                                    const newGuest = {...prev};
                                                    newGuest.checkins = prev.checkins.filter(ci => ci.id !== c.id);
                                                    return newGuest;
                                                });
                                            });
                                        }
                                    }}
                                    />
                            </td>
                        </tr>)}
                        </tbody>
                    </table>}
                </div>

                <div className='horizontal'>
                    <div className='grower'/>
                </div>
            </CenteredPopup>}
        </div>
    );
}