import React, {useCallback, useEffect, useMemo, useState} from 'react';
import debounce from "lodash.debounce";
import {SharedMessageApi} from "../../../api";
import ColorDropdown from "../../color-dropdown/ColorDropdown";
import './sektion.css';
import SimpleInput from "../../simple-input/SimpleInput";

export default function Sektion({
                                    sektion,
                                    no_edit = false,
                                    display_status = false,
                                    show_client_edit = false,
                                    is_client = false,
                                    className = '',
                                    updateSektion = () => {},
                                }) {
    const [localSektion, setLocalSektion] = useState(null);

    useEffect(() => {
        setLocalSektion(sektion);
    }, [sektion]);

    const updateMessageBackend = useCallback((updated_msg) => {
        SharedMessageApi.create_or_update(sektion.message_identifier, sektion.event_id, sektion.venue_id, updated_msg)
    }, [sektion]);

    const _updateMessage = useMemo(() => debounce(updateMessageBackend, 800), [updateMessageBackend]);

    const setMessage = useCallback((msg) => {
        _updateMessage(msg)
        setLocalSektion(prev => ({...prev, message: msg}))
        updateSektion(prev => ({...prev, message: msg}))
    }, [_updateMessage]);

    const {title, text} = useMemo(() => {
        if (!localSektion) return {title: '', text: ''};
        const [title, text] = localSektion.message.split(';;');

        return {title, text};
    }, [localSektion]);

    const client_visibility_translation = useMemo(() => ({
        'hidden': 'Utilgængelig',
        'read': 'Må læse',
        'write': 'Må skrive'
    }), []);

    if (!localSektion) return null;

    return (<div className={`sektion ${className}`}>
            <div className='horizontal'>
                {!is_client && <input type='text'
                                      disabled={no_edit}
                                      placeholder='Navn'
                                      value={title} onChange={e => {
                    const value = e.target.value;
                    setMessage(`${value};;${text}`)
                }}/>}
                {is_client && <h2>{title}</h2>}

                {show_client_edit && <>
                    <div className='grower'/>

                    <SimpleInput
                        options={Object.values(client_visibility_translation)}
                        title='Klient adgang'
                        onlySelectableOptions
                        value={client_visibility_translation[localSektion.client_visibility]}
                        onChanged={value => {
                            const trans = Object.keys(client_visibility_translation).find(key => client_visibility_translation[key] === value)
                            setLocalSektion(p => ({
                                ...p,
                                client_visibility: trans
                            }))
                            SharedMessageApi.set_client_visibility(sektion.message_identifier, sektion.event_id, sektion.venue_id, trans)
                        }}
                    />
                </>}
                <div className='grower'/>

                {display_status && <div>
                    <label>Status:</label>
                    <ColorDropdown
                        options={['red', 'yellow', 'green']}
                        selectedColor={localSektion.status_color}
                        onChange={value => {
                            setLocalSektion(p => ({
                                ...p,
                                status_color: value
                            }))
                            SharedMessageApi.set_status_color(sektion.message_identifier, sektion.event_id, sektion.venue_id, value)
                        }}
                    />
                </div>
                }

            </div>
            <textarea placeholder='Tekst'
                      disabled={no_edit}
                      value={text} onChange={e => {
                const value = e.target.value;
                setMessage(`${title};;${value}`)
            }}/>
            {text.split('\n').map((line, idx) => <p className='printer-friendly-paragraphs' key={idx}>{line}</p>)}
        </div>
    )
}
