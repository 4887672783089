import React, {useEffect, useMemo, useRef, useState} from "react";
import {proposalApi} from "../../../api";
import ClientEventPackage from "../../../component/package-components/client-event-package/ClientEventPackage";
import EconomyTable from "../../../component/package-components/economy-table/EconomyTable";
import {useNavigate, useParams} from "react-router-dom";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import html2pdf from "html2pdf.js";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import SimpleCheckbox from "../../../component/simple-checkbox/SimpleCheckbox";
import documentIcon from '../../../assets/document.svg';
import './client-proposal-page.css';
import pdfIcon from "../../../assets/pdf_icon_white.svg";
import ValueKeyLabel from "../../../component/value-key-label/ValueKeyLabel";
import {toDanishDate} from "../../../component/date_functions";
import SharedMessage from "../../../component/shared-message/SharedMessage";

export default function ClientProposalPage({_token = null, propProposalRef = null, onLoaded = null}) {
    const {proposalToken: urlToken} = useParams();

    const proposalToken = useMemo(() => _token || urlToken, [_token, urlToken]);

    const [packages, setPackages] = useState(null);
    const [proposal, setProposal] = useState(null);
    const [event, setEvent] = useState(null);
    const [customer, setCustomer] = useState(null);

    const _myProposalRef = useRef(null);

    const proposalRef = useMemo(() => propProposalRef || _myProposalRef, [propProposalRef]);

    const [generatedPdfSignedUrl, setGeneratedPdfSignedUrl] = useState(null);
    const [showSignPopup, setShowSignPopup] = useState(false);

    const [theyAreWhoTheySay, setTheyAreWhoTheySay] = useState(false);
    const [theyAcceptTerms, setTheyAcceptTerms] = useState(false);

    const [logo, setLogo] = useState(null);
    const [documentIntro, setDocumentIntro] = useState(null);

    useEffect(() => {
        if (!proposalToken) {
            return;
        }
        proposalApi.viewProposalUsingtoken(proposalToken).then(res => {
            const {proposal, event, customer, packages, pdf_signed_url, logo_signed_url, document_intro} = res;
            setProposal(proposal);
            setEvent(event);
            setCustomer(customer);
            setPackages(packages);
            setGeneratedPdfSignedUrl(pdf_signed_url)
            setLogo(logo_signed_url)
            setDocumentIntro(document_intro)
        });
    }, [proposalToken]);

    const exportToPDF = () => {
        const element = proposalRef.current;

        // Add print class to make some custom styling
        element.classList.add('printable-wrapper');

        // snailcase event title
        const event_name = event.title.replace(/ /g, '_').toLowerCase();

        const options = {
            margin: 0.2,
            filename: `tilbud_${event_name}.pdf`,
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'a4', orientation: 'portrait'}
        };
        html2pdf().set(options).from(element).toContainer().save();
        element.classList.remove('printable-wrapper');
    };

    if (!packages || !proposal || !event || !customer) {
        return null;
    }

    return (<div className='client-proposal-page' onLoad={onLoaded ? onLoaded() : _ => {}}>
        <main ref={proposalRef} className='printable-wrapper'>
            <div className='document-header'>
                <ValueKeyLabel title='Dato'>
                    {toDanishDate(new Date(event.date))}
                </ValueKeyLabel>
                <ValueKeyLabel title='Tid'>
                    {event.timestamp_from && event.timestamp_from}-{event.timestamp_to && event.timestamp_to}
                </ValueKeyLabel>
                <ValueKeyLabel title='Gæster'>
                    {event.expected_guests}
                </ValueKeyLabel>
                {logo && <div className='document-intro-image-wrapper'>
                    <img src={logo} alt='logo' className='document-intro-image'/>
                </div>}
            </div>

            <h1 className='proposal-client-h1'><img src={documentIcon}/>{proposal.name}</h1>

            {documentIntro && <p className='document-intro'>
                {documentIntro}
            </p>}
            {packages && packages.sort((a, b) => a.proposal_order - b.proposal_order).filter(e => e.type !== 'economy').map(p =>
                <ClientEventPackage key={p.id} pkg={p}/>)}
            <h2>Økonomi</h2>
            <EconomyTable packages={packages} expected_guests={event.expected_guests}/>
        </main>
        {proposal.status === 'afsendt' && <div className='accept-tools horizontal'>
            <div className='grower'/>
            <SimpleButton value='Accepter' onClick={_ => {
                setShowSignPopup(true);
            }}/>
        </div>}
        {proposal.status === 'godkendt' && <div className='accept-tools horizontal accepted'>
            <div className='grower'/>
            <label className='accepted-label'>Tilbuddet er blevet accepteret</label>
            <div className='grower'/>

            <SimpleButton
                value='Download som PDF'
                icon={pdfIcon}
                onClick={_ => exportToPDF()}
            />
            <div className='grower'/>
        </div>}
        <CenteredPopup className='final-accept-popup' showPopup={showSignPopup} closePopup={_ => {
            setShowSignPopup(false);
            setTheyAcceptTerms(false);
            setTheyAreWhoTheySay(false);
            setGeneratedPdfSignedUrl(null);
        }}>
            <h1>Underskriver aftale:</h1>
            {generatedPdfSignedUrl && <iframe
                src={generatedPdfSignedUrl}>
            </iframe>}
            <div className='terms-and-conditions'>
                <div className='horizontal'>
                    <SimpleButton
                        value='Download som PDF'
                        icon={pdfIcon}
                        onClick={_ => exportToPDF()}
                    />
                    <div className='grower' />
                    <SimpleButton
                        value='Accepter'
                        onClick={_ => {
                            proposalApi.acceptProposal(proposal.id, proposalToken).then(res => {
                                console.log("Proposal signed:", res);
                                setShowSignPopup(false);
                            }).then(setProposal)
                        }}/>
                </div>
            </div>
        </CenteredPopup>
    </div>);
}
